<template>
  <div class="app-container v">
    <div class="head-container">
      <quick-select v-model="query.buyerId" filterable url="api/distributor" placeholder="选择经销商" value-field="enterpriseId" auto-select-first-option class="filter-item" style="width: 180px;" @change="reQuery" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="记录日期起始" end-placeholder="记录日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :clearable="false" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />

      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" highlight-current-row empty-text="未找到对账信息" height="200px">
      <!-- 审核标志	日期	发票号码	购货单位代码	购货单位	产品长代码	产品名称	规格型号	单位	数量	单价	折扣率(%)	折扣额	价税合计	税额	凭证字号	摘要	不含税金额	订单单号	源单单号	备注	产品分类	发票号	客户单号	客户料号 -->
      <el-table-column prop="DocDate" label="单据日期" width="90" :formatter="r => { return r.DocDate?new Date(r.DocDate).format('yyyy/MM/dd'):'' }" />
      <el-table-column prop="DocNum" label="发票号码" />
      <el-table-column prop="CustomerNum" width="120" label="购货单位编码" />
      <el-table-column prop="CustomerName" min-width="180" label="购货单位" />
      <el-table-column prop="Item.ItemNum" width="120" label="产品代码" />
      <el-table-column prop="Item.ItemName" min-width="150" label="产品名称" />
      <el-table-column prop="Item.ItemModel" min-width="120" label="规格型号" />
      <el-table-column prop="Item.Measurement.Name" label="单位" />
      <el-table-column prop="Quantity" label="数量" />
      <el-table-column prop="UnitPrice" label="单价" />
      <el-table-column prop="DiscountRate" label="折扣率(%)" />
      <el-table-column prop="DiscountAmount" label="折扣额" />
      <el-table-column prop="LineTotal" label="价税合计" />
      <el-table-column prop="TaxAmount" label="税额" />
      <el-table-column prop="Digest" label="摘要" />
      <el-table-column label="不含税金额" width="100" :formatter="r=>{return (r.LineTotal-r.TaxAmount).toFixed(2)}" />
      <el-table-column prop="SalesOrderNum" label="订单单号" />
      <el-table-column prop="BaseNum" label="源单单号" />
      <el-table-column prop="Description" label="备注" show-overflow-tooltip />
    </el-table>
    <!-- <el-pagination v-if="statementType!==sertaStatementType" :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" /> -->
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      queryable: false,
      query: {
        buyerId: null,
        dateRange: [],
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/invoices/seller";
      let query = JSON.parse(JSON.stringify(this.query));
      let required = false;
      if (query.buyerId) {
        if (query.dateRange && query.dateRange.length === 2) {
          required = true;
          query.begTime = query.dateRange[0];
          query.endTime = query.dateRange[1];
        }
        delete query.dateRange;
      }
      if (required) {
        this.params = query;
        this.params.sort = this.sort;
      } else {
        this.$message.info("请选择经销商和记录日期范围进行搜索。");
      }
      this.queryable = required;
      return required;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },

    reQuery() {
      this.current = null;
      this.toQuery();
      // this.calcCount();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/invoices/seller/download", this.params)
        .then((result) => {
          downloadFile(result, "经销商发票明细", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    let now = new Date();
    this.query.dateRange = [
      new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate(),
        0,
        0,
        0
      ).getTime(),
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      ).getTime(),
    ];
  },
};
</script>